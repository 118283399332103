export default class extends Controller {
  static targets = ['currentMeeting']

  search(event) {
    const searchText = event.target.value;
    fetch(`/meetings/new_form?search=${searchText}`)
    .then((response) => response.json())
    .then(data => {
        const selectBox = document.getElementById('guest-container');
        selectBox.innerHTML = ""; // Clear previous results
        if (data.guests.length === 0) {
          const noGuestOption = document.createElement("option");
          noGuestOption.textContent = "No guests found";
          selectBox.appendChild(noGuestOption);
        } else {
        if(data && Array.isArray(data.guests) && data.guests.length > 0){
          data.guests.forEach(guest => {
            const optionElement = document.createElement("option");
            optionElement.className = "custom-options pm-hover cursor-pointer";
            optionElement.value = guest.id;
            optionElement.textContent = `${guest.first_name} ${guest.last_name} - ${guest.phone}`;
            optionElement.addEventListener('click', () => {
              this.setSearchField(guest);
            });
            selectBox.appendChild(optionElement);
          });
        }else{
          document.getElementById('guest_id').value = '';
        }
       }
      })
    .catch((error) => {
      console.error("Error fetching guests:", error);
    });
  }

  setSearchField(guest) {
   document.getElementById('guest_search').value = `${guest.first_name} ${guest.last_name}`;
   document.getElementById('meeting_first_name').value = `${guest.first_name}`;
   document.getElementById('meeting_last_name').value = `${guest.last_name}`;
   document.getElementById('guest_id').value = `${guest.id}`;
   document.getElementById('country_code_dropdown').value = `${guest.country_code}`
   document.getElementById('meeting_phone').value = `${guest.phone}`
   document.getElementById('guest-container').innerHTML = ""; // Clear the dropdown
 }

 changeBgColor(){
   document.querySelectorAll('.bg-light').forEach(function(element) {
      element.classList.remove('bg-light');
    });
    this.currentMeetingTarget.classList.add('bg-light');
 }
}
